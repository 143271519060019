<template>
  <div style="overflow: hidden">
    <a-row
      type="flex"
      justify="center"
      align="middle"
      :gutter="8"
      style="margin-top: 50px"
    >
      <a-col
        class="brief-container"
        :xl="9"
        :lg="11"
        :md="12"
        :sm="13"
        :xs="18"
      >
        <h3>{{ $t("public.about_banner_title") }}</h3>
        <p>{{ $t("public.about_banner_text_01") }}</p>
        <p>{{ $t("public.about_banner_text_02") }}</p>
      </a-col>
      <a-col
        :xl="6"
        :lg="8"
        :md="9"
        :sm="13"
        :xs="18"
        style="margin-bottom: 70px"
      >
        <div class="empty-layer">
          <img
            src="@/assets/image/public/aboutus-img.png"
            alt=""
            style="width: 100%"
          />
        </div>
      </a-col>
    </a-row>
    <div class="divider-title">{{ $t("public.our_motivation") }}</div>
    <a-row type="flex" justify="center" :gutter="48" style="margin-top: 60px">
      <a-col
        v-for="(item, index) in motivationList"
        :key="index"
        class="motivation-info"
      >
        <a-space direction="vertical" align="center">
          <img :src="item.image" alt="" style="width: 80px" />
          <h3>{{ $t(item.title) }}</h3>
          <span>{{ $t(item.text) }}</span>
        </a-space>
      </a-col>
    </a-row>
    <div class="divider-title">
      {{ $t("public.our_values") }}
    </div>
    <a-row type="flex" justify="center" style="margin-top: 70px">
      <a-col>
        <a-row
          type="flex"
          justify="center"
          :xl="12"
          :lg="14"
          :md="16"
          :sm="18"
          :xs="20"
        >
          <a-col
            :span="8"
            class="values-info"
            v-for="(item, index) in valuesList"
            :key="index"
          >
            <a-space direction="vertical" align="center">
              <img :src="item.image" alt="" style="width: 60px" />
              <span>{{ item.cnTitle }}</span>
              <span>{{ item.enTitle }}</span>
            </a-space>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
    <a-row type="flex" justify="center" style="margin-top: 70px">
      <a-col
        :xl="12"
        :lg="14"
        :md="16"
        :sm="18"
        :xs="20"

      >
        <div class="divider-title">{{ $t("public.about_saleasy") }}</div>
        <p style="margin-top: 40px; line-height: 36px;">{{ $t('public.about_saleasy_text') }}</p>
        <p style="margin-bottom: 5px;">{{ $t('logistics.contact_information') }}</p>
        <p style="margin-bottom: 5px;">{{ $t('public.email') }}: <a :href="'mailto:' + contactInfo.email">{{ contactInfo.email }}</a></p>
        <p style="margin-bottom: 5px;">{{ $t('logistics.contact_number') }}: {{ contactInfo.phone }}</p>
        <p style="margin-bottom: 70px;">{{ $t('public.contact_address') }}: {{ contactInfo.address }}</p>
      </a-col>
    </a-row>
    <WechatDot />
  </div>
</template>

<script>
import { Row, Col, Space } from "ant-design-vue";
import { defineComponent, ref } from "vue";
import WechatDot from './components/WechatDot.vue';

export default defineComponent({
  name: "public_about_us",
  components: {
    ARow: Row,
    ACol: Col,
    ASpace: Space,
    WechatDot,
  },
  setup() {
    const motivationList = ref([
      {
        image: require("@/assets/image/public/h1-1.png"),
        title: "public.mission",
        text: "public.motivation_text_01",
      },
      {
        image: require("@/assets/image/public/h1-2.png"),
        title: "public.vision",
        text: "public.motivation_text_02",
      },
      {
        image: require("@/assets/image/public/h1-3.png"),
        title: "public.target",
        text: "public.motivation_text_03",
      },
    ]);

    const valuesList = ref([
      {
        image: require("@/assets/image/public/h2-1.png"),
        cnTitle: "客户第一",
        enTitle: "Customers First",
      },
      {
        image: require("@/assets/image/public/h2-2.png"),
        cnTitle: "诚信简单",
        enTitle: "Honesty and Simple",
      },
      {
        image: require("@/assets/image/public/h2-3.png"),
        cnTitle: "勇于改变",
        enTitle: "Dare to Change",
      },
      {
        image: require("@/assets/image/public/h3-1.png"),
        cnTitle: "合作共赢",
        enTitle: "Cooperation",
      },
      {
        image: require("@/assets/image/public/h3-2.png"),
        cnTitle: "保持学习",
        enTitle: "Continuous Learning",
      },
      {
        image: require("@/assets/image/public/h3-3.png"),
        cnTitle: "匠人精神",
        enTitle: "Craftsmanship",
      },
    ]);

    const contactInfo = {
      email: "cooperation@saleasy.com",
      phone: "0755-84536954",
      address: "深圳市龙岗区坂田街道乐荟中心TOWER6（6栋15层A）",
    }

    return {
      motivationList,
      valuesList,
      contactInfo,
    };
  },
});
</script>

<style lang="less" scoped>
.brief-container {
  margin-bottom: 70px;
  h3 {
    font-size: 30px;
    font-weight: 600;
  }
  p {
    margin: 0;
    line-height: 36px;
    color: rgba(0, 0, 0, 0.7);
  }
}

.divider-title {
  text-align: center;
  font-size: 24px;
  font-weight: 500;
}

.motivation-info {
  width: 310px;
  margin-bottom: 60px;
  text-align: center;
  h3 {
    margin-top: 20px;
    font-size: 18px;
    font-weight: 600;
  }
  span {
    font-size: 16px;
    font-weight: 500;
  }
}

.values-info {
  margin-bottom: 60px;
  text-align: center;
  span {
    font-size: 18px;
    font-weight: 500;
  }
}
</style>